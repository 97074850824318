import React from 'react';
import Papa from 'papaparse';
import { Button } from 'semantic-ui-react';
import { BonusPayment } from './types';

interface BonusPaymentsTableDownloadProps {
  tableData: BonusPayment[];
}

const BonusPaymentsTableDownload = ({ tableData }: BonusPaymentsTableDownloadProps) => {
  const downloadCSV = () => {
    const orderedData = tableData.map(row => ({
      ID: row.id,
      NetworkID: row.networkId,
      NetworkName: row.networkName,
      NetworkMerchantID: row.networkMerchantId,
      NetworkMerchantName: row.networkMerchantName,
      ApplicationName: row.applicationName,
      CreatedDate: row.createdDate.split('T')[0],
      ModifiedDate: row.modifiedDate.split('T')[0],
      ModifiedAuthor: row.modifiedAuthor,
      SaleAmount: `${row.saleAmount} ${row.saleCurrencyCode}`, // uses saleCurrencyCode
      CommissionAmount: `${row.commissionAmount} ${row.applicationCurrencyCode}`, // uses applicationCurrencyCode
      EventDate: row.eventDate.split('T')[0],
      DeviceID: row.deviceId,
      MerchantOrderID: row.merchantOrderId,
      TrackingCode: row.trackingCode,
      BonusAmount: `${row.bonusAmount} ${row.applicationCurrencyCode}`, // uses applicationCurrencyCode
      USDBonusAmount: `${row.usdBonusAmount} USD`,
      Status: row.status,
    }));
    const csv = Papa.unparse(orderedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    const dateToday = new Date().toISOString().split('T')[0];
    const fileName = `bonus-payments-${dateToday}`;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="flex justify-end mr-2">
      <Button className="orange-rounded-button" onClick={downloadCSV}>
        Download Table
      </Button>
    </div>
  );
};

export default BonusPaymentsTableDownload;
