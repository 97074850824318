import React from 'react';
import Papa from 'papaparse';
import { Button } from 'semantic-ui-react';
import { SalesDashStatsData } from './types';

interface SalesDashTableDownloadProps {
  tableData: SalesDashStatsData[];
  startDate: string;
  endDate: string;
  groupBy: string;
}

export default function StatsTableDownload({ tableData, startDate, endDate, groupBy }: SalesDashTableDownloadProps) {
  const downloadCSV = () => {
    // Reorder data to match table column order
    const orderedData = tableData.map(row => ({
      ...(groupBy === 'date' ? { Date: row.Date } : { ID: row.ID, Name: row.Name }),
      CommissionCount: row.CommissionCount,
      PositiveCommissionCount: row.PositiveCommissionCount,
      NegativeCommissionCount: row.NegativeCommissionCount,
      ZeroCommissionCount: row.ZeroCommissionCount,
      GrossSaleAmount: row.GrossSaleAmount,
      GrossCommissionAmount: row.GrossCommissionAmount,
      NetSaleAmount: row.NetSaleAmount,
      NetCommissionAmount: row.NetCommissionAmount,
      AvgCommissionRate: row.AvgCommissionRate,
      ClickCount: row.ClickCount,
      OrderCount: row.OrderCount,
    }));

    const csv = Papa.unparse(orderedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);

    const fileName = startDate + '-' + endDate + '-stats-by-' + groupBy;
    a.download = fileName;
    a.click();
  };

  return (
    <div className="flex justify-end">
      <Button className="orange-rounded-button" onClick={downloadCSV}>
        Download Table
      </Button>
    </div>
  );
}
