import { parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  SalesDashFormState,
  SalesDashSearchParam,
  SalesDashGroupByCategory,
  SalesDashFilterCategory,
  SalesDashDateTruncCategory,
} from '../../../components/SalesDashboardV2/types';

const today = new Date();
const oneWeekAgo = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);

const dateToday = new Intl.DateTimeFormat('fr-CA').format(today);
const dateOneWeekAgo = new Intl.DateTimeFormat('fr-CA').format(oneWeekAgo);

export const formatURI = (formState: SalesDashFormState) => {
  const {
    startDate,
    endDate,
    groupBy,
    dateTrunc,
    appsToInclude,
    merchantsToInclude,
    networksToInclude,
    appsToExclude,
    merchantsToExclude,
    networksToExclude,
  } = formState;

  let searchURI = `?start_date=${startDate}`;

  const uriValues = [
    { name: 'end_date', value: endDate },
    { name: 'group_by', value: groupBy },
  ];

  uriValues.forEach(i => {
    if (i.value !== '') {
      searchURI += `&${i.name}=${i.value}`;
    }
  });

  searchURI += '&sort_by=date';
  searchURI += `&date_trunc=${dateTrunc || 'day'}`;

  // If inclusion or exclusion filter was applied add them to searchURI
  if (appsToInclude?.length) {
    appsToInclude.forEach(id => {
      searchURI += `&application_id=${id}`;
    });
  }
  if (merchantsToInclude?.length) {
    merchantsToInclude.forEach(id => {
      searchURI += `&merchant_id=${id}`;
    });
  }
  if (networksToInclude?.length) {
    networksToInclude.forEach(id => {
      searchURI += `&network_id=${id}`;
    });
  }
  if (appsToExclude?.length) {
    appsToExclude.forEach(id => {
      searchURI += `&not_application_id=${id}`;
    });
  }
  if (merchantsToExclude?.length) {
    merchantsToExclude.forEach(id => {
      searchURI += `&not_merchant_id=${id}`;
    });
  }
  if (networksToExclude?.length) {
    networksToExclude.forEach(id => {
      searchURI += `&not_network_id=${id}`;
    });
  }

  return searchURI;
};

export const formatFilterNumbers = (list: string[] | string) => {
  if (typeof list === 'string') {
    list = list.split(',');
  }
  return list.map(item => Number(item));
};

export const formatDateTime = (dateStr: Date) => {
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };

  if (!dateStr) return '';

  const date = parseDateStringWithTimezone(dateStr);

  // Format the date and time
  const lastUpdatedDate = new Intl.DateTimeFormat('en').format(date);
  const lastUpdatedTime = new Intl.DateTimeFormat('en', timeOptions as Intl.DateTimeFormatOptions).format(date); // Assigned Intl.DateTimeFormatOptions type because although timeStyle is a property of Intl.DateTimeFormatOptions, it is not being recognized and is throwing an error in the console

  return `${lastUpdatedDate} at ${lastUpdatedTime}`;
};

export const parseDateStringWithTimezone = (dateStr: Date | string) => {
  // if argument is a Date object already, return it
  if (dateStr instanceof Date) {
    return dateStr;
  }

  // Extract the timezone offset from the input string
  const dateStrSegments = dateStr.split(' ');
  const formattedDateStr = dateStrSegments[0];
  const timezoneOffset = dateStrSegments[dateStrSegments.length - 1];

  // Parse the ISO date string and adjust to the specified timezone
  return utcToZonedTime(parseISO(formattedDateStr), timezoneOffset);
};

export const isValidGroupByCategory = (groupBy: string | null): groupBy is SalesDashGroupByCategory => {
  if (groupBy == null) return false;
  return ['date', 'application_id', 'merchant_id', 'network_id'].includes(groupBy);
};

export const isValidDateTruncCategory = (dateTrunc: string | null): dateTrunc is SalesDashDateTruncCategory => {
  if (dateTrunc == null) return false;
  return ['day', 'week', 'month', 'quarter', 'year'].includes(dateTrunc);
};

export const isValidFilterCategory = (key: string): key is SalesDashFilterCategory => {
  return [
    'application_id',
    'merchant_id',
    'network_id',
    'not_application_id',
    'not_merchant_id',
    'not_network_id',
    'filterAppId',
    'filterMerchantId',
    'filterNetworkId',
  ].includes(key);
};

export const translateGroupByName = (groupBy: string): string => {
  let newGroupBy = 'date';
  if (groupBy === 'application') newGroupBy = 'application_id';
  else if (groupBy === 'merchant') newGroupBy = 'merchant_id';
  else if (groupBy === 'network') newGroupBy = 'network_id';

  return newGroupBy;
};

export const getSearchParams = (): SalesDashSearchParam => {
  const urlParams = new URLSearchParams(window.location.search);
  const start_date = urlParams.get('start_date') || dateOneWeekAgo;
  const end_date = urlParams.get('end_date') || dateToday;
  const not_application_id = urlParams.getAll('not_application_id');
  const not_network_id = urlParams.getAll('not_network_id');
  let not_merchant_id = urlParams.getAll('not_merchant_id');
  const group_by_param = urlParams.get('group_by');
  let group_by: SalesDashGroupByCategory = isValidGroupByCategory(group_by_param) ? group_by_param : 'application_id';
  const date_trunc_param = urlParams.get('date_trunc');
  let date_trunc: SalesDashDateTruncCategory = isValidDateTruncCategory(date_trunc_param) ? date_trunc_param : 'day';
  let application_id = urlParams.getAll('application_id');
  let merchant_id = urlParams.getAll('merchant_id');
  let network_id = urlParams.getAll('network_id');

  // transform query parameters associated with old Sales Dash parameter format
  const dateGroupByOptions = ['day', 'week', 'month', 'quarter', 'year'];

  if (dateGroupByOptions.includes(group_by)) {
    date_trunc = isValidDateTruncCategory(group_by) ? group_by : 'day';
    group_by = 'date';
  } else if (group_by !== 'date' && group_by.includes('_id')) {
    translateGroupByName(group_by);
  }

  const filterAppIdParam = urlParams.get('filter_app_id');
  if (filterAppIdParam) {
    application_id = filterAppIdParam.split(',');
  }

  const filterNetworkIdParam = urlParams.get('filter_network_id');
  if (filterNetworkIdParam) {
    network_id = filterNetworkIdParam.split(',');
  }

  const filterMerchantIdParam = urlParams.get('filter_merchant_id');
  const filterMerchantIncludeParam = urlParams.get('filter_merchant_include');
  if (filterMerchantIncludeParam === 'true') {
    merchant_id = filterMerchantIdParam?.split(',') || [];
  } else if (filterMerchantIncludeParam === 'false') {
    not_merchant_id = filterMerchantIdParam?.split(',') || [];
  }
  // end query parameter transformations

  return {
    start_date,
    end_date,
    group_by,
    date_trunc,
    application_id,
    merchant_id,
    network_id,
    not_application_id,
    not_merchant_id,
    not_network_id,
  };
};
