import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Form, Button, Dropdown as DropdownComponent, DropdownProps } from 'semantic-ui-react';
import { request } from 'helpers/api';
import {
  SalesDashAppGroupFilterOption,
  SalesDashDropdownOption,
  SalesDashFormState,
  SalesDashFilterOption,
  DropdownChangeEvent,
  Merchant,
  SearchResult,
} from './types';
import { groupByOptions, dateIntervalOptions } from '../../pages/SalesDashboardV2/Constants';
import { isValidGroupByCategory, isValidDateTruncCategory } from '../../pages/SalesDashboardV2/helpers';
import Dropdown from '../Dropdown/Dropdown';

interface SalesDashFormProps {
  formState: SalesDashFormState;
  getAppGroupOptions: () => Promise<SalesDashAppGroupFilterOption[]>;
  getFilterOptions: (category: string) => Promise<SalesDashFilterOption[]>;
  setError: (error: string) => void;
  setFormState: (formState: SalesDashFormState) => void;
  getMerchant: (merchantId: number) => Promise<Merchant>;
}

interface AppSearchQuery {
  appsToInclude: string;
  appsToExclude: string;
}

const initialAppSearchQuery: AppSearchQuery = {
  appsToInclude: '',
  appsToExclude: '',
};

const getAppOptionContent = (option: SalesDashDropdownOption) =>
  option.groupApplications ? (
    <DropdownComponent.Header className="!p-0">{option.text}</DropdownComponent.Header>
  ) : (
    <DropdownComponent.Item className="!pl-6">{option.text}</DropdownComponent.Item>
  );

const SalesDashForm = ({
  formState,
  getAppGroupOptions,
  getFilterOptions,
  setError,
  setFormState,
  getMerchant,
}: SalesDashFormProps) => {
  const [formFieldState, setFormFieldState] = useState<SalesDashFormState>(formState);
  const [appOptions, setAppOptions] = useState<SalesDashDropdownOption[]>([]);
  const [merchantOptions, setMerchantOptions] = useState<SalesDashDropdownOption[]>([]);
  const [networkOptions, setNetworkOptions] = useState<SalesDashDropdownOption[]>([]);
  const [isMerchantSearchLoading, setIsMerchantSearchLoading] = useState(false);
  const [appSearchQuery, setAppSearchQuery] = useState<AppSearchQuery>(initialAppSearchQuery);

  const {
    startDate,
    endDate,
    groupBy,
    dateTrunc,
    appsToInclude,
    merchantsToInclude,
    networksToInclude,
    appsToExclude,
    merchantsToExclude,
    networksToExclude,
  } = formFieldState;

  const today = new Date();
  const dateToday = new Intl.DateTimeFormat('fr-CA').format(today);
  const appsToIncludeSet = new Set([...appsToInclude]);
  const appsToExcludeSet = new Set([...appsToExclude]);

  const filteredAppOptions = {
    appsToInclude: appOptions.filter(
      option =>
        option.text.toLowerCase().includes(appSearchQuery.appsToInclude.toLowerCase()) ||
        appsToIncludeSet.has(option.value), // Show selected apps even when search term does not match them
    ),
    appsToExclude: appOptions.filter(
      option =>
        option.text.toLowerCase().includes(appSearchQuery.appsToExclude.toLowerCase()) ||
        appsToExcludeSet.has(option.value), // Show selected apps even when search term does not match them
    ),
  };

  const getAppGroupItems = async () => {
    let appGroupOptions: SalesDashAppGroupFilterOption[] = [];
    try {
      appGroupOptions = await getAppGroupOptions();
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }

    const dropdownOptions: SalesDashDropdownOption[] = appGroupOptions.flatMap(group => {
      const groupOption = {
        key: group.adminId,
        name: group.adminName,
        text: `${group.adminId} - ${group.adminName}`,
        value: group.adminId,
        groupApplications: group.applications.map(app => app.ID),
      };

      const applicationOptions = group.applications.map(app => ({
        key: app.ID,
        name: app.Name,
        text: ` ${app.ID} - ${app.Name}`,
        value: app.ID,
        groupId: group.adminId,
      }));

      return [groupOption, ...applicationOptions];
    });

    setAppOptions(dropdownOptions);
  };

  const getFilterItems = async (category: string) => {
    let filterItems: SalesDashFilterOption[] = [];
    try {
      filterItems = await getFilterOptions(category);
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }

    const dropdownOptions: SalesDashDropdownOption[] = filterItems.map(application => {
      return {
        key: application.ID,
        text: `${application.ID} - ${application.Name}`,
        name: application.Name,
        value: application.ID,
      };
    });

    category === 'network' && setNetworkOptions(dropdownOptions);
  };

  const handleDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, { value, name }: DropdownChangeEvent) => {
    setFormFieldState({ ...formFieldState, [name]: value });
  };

  const search = async (query: string) => {
    setIsMerchantSearchLoading(true);
    try {
      const searchResponse = await request('GET', `/api/search?q=${encodeURIComponent(query)}`);
      const searchResults: SearchResult[] = await searchResponse.json();
      const merchantSearchResults = searchResults.filter(result => result.Type === 'merchant');
      const merchantSearchOptions = merchantSearchResults.map(result => ({
        key: result.ID,
        text: `${result.ID} - ${result.Value}`,
        name: result.Value,
        value: result.ID,
      }));
      setMerchantOptions(merchantSearchOptions);
    } catch (err) {
      if (typeof err === 'string') {
        setError(err);
      } else if (err instanceof Error) {
        setError(err.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
    setIsMerchantSearchLoading(false);
  };

  const debouncedSearch = debounce(search, 300);

  const handleMerchantSearch = (searchQuery: string) => {
    setMerchantOptions([]);
    if (searchQuery.length > 2) debouncedSearch(searchQuery);
  };

  const getMerchantOptionData = async (merchantId: number) => {
    const merchant = await getMerchant(merchantId);
    return {
      key: merchant.ID,
      text: `${merchant.ID} - ${merchant.Name}`,
      name: merchant.Name,
      value: merchant.ID,
    };
  };

  const handleAppFilterChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
    name: 'appsToInclude' | 'appsToExclude',
  ) => {
    const formFieldListCopy: number[] = [...formFieldState[name]];
    const selectedValue = (e.target as HTMLElement).textContent?.split(' - ')[0] || '';
    const selectedOption = appOptions.find(option => option.value === parseInt(selectedValue));
    const optionDeleted = e.currentTarget.classList.contains('delete');
    const optionCleared = e.currentTarget.classList.contains('clear');

    // Option is selected
    if (selectedOption) {
      const { groupApplications, value } = selectedOption;

      // Selected option is an app group
      if (groupApplications) {
        const newSelectedOptions = [...new Set([...formFieldListCopy, value, ...groupApplications])];
        const filteredMatches = filteredAppOptions[name].filter(option => !newSelectedOptions.includes(option.value));
        if (filteredMatches.length === 0) {
          setAppSearchQuery(
            name === 'appsToInclude'
              ? { ...appSearchQuery, appsToInclude: '' }
              : { ...appSearchQuery, appsToExclude: '' },
          );
        }
        setFormFieldState({ ...formFieldState, [name]: newSelectedOptions });
        // Selected option is a single app
      } else {
        formFieldListCopy.push(value);
        setFormFieldState({ ...formFieldState, [name]: formFieldListCopy });
      }
      // Option is deleted/cleared
    } else {
      if (optionDeleted) {
        const deletedValue = formFieldListCopy.filter(value => {
          return Array.isArray(data.value) && !data.value.includes(value);
        })[0];
        const deletedOption = appOptions.find(option => option.value === deletedValue);

        // Deleted option is an app group
        if (deletedOption?.groupApplications) {
          const updatedOptions = formFieldListCopy.filter(
            value => !deletedOption.groupApplications?.includes(value) && deletedValue !== value,
          );
          setFormFieldState({ ...formFieldState, [name]: updatedOptions });
          // Deleted option is a single app
        } else {
          setFormFieldState({ ...formFieldState, [name]: data.value });
        }
      }

      // Option is cleared
      if (optionCleared) {
        setFormFieldState({ ...formFieldState, [name]: data.value });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newState = {
      ...formState,
      startDate: startDate,
      endDate: endDate,
      groupBy: groupBy,
      dateTrunc: dateTrunc,
      appsToInclude,
      merchantsToInclude,
      networksToInclude,
      appsToExclude,
      merchantsToExclude,
      networksToExclude,
    };

    setFormState(newState);
    setFormFieldState(newState);
  };

  useEffect(() => {
    getFilterItems('merchant');
    getFilterItems('network');
    getAppGroupItems();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          label="Start Date"
          name="startDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={startDate}
          onChange={e => setFormFieldState({ ...formFieldState, startDate: e.target.value })}
        />
        <Form.Input
          label="End Date"
          name="endDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={endDate}
          onChange={e => setFormFieldState({ ...formFieldState, endDate: e.target.value })}
        />
        <Form.Select
          label="Group By"
          name="groupBy"
          value={groupBy}
          options={groupByOptions}
          icon={<i className="dropdown icon text-wildfire-orange" />}
          onChange={(_, { value }) => {
            if (typeof value === 'string') {
              if (isValidGroupByCategory(value)) {
                setFormFieldState({ ...formFieldState, groupBy: value });
              }
            }
          }}
        />
        <Form.Select
          label="Date Interval"
          name="dateTrunc"
          value={dateTrunc}
          options={dateIntervalOptions}
          icon={<i className="dropdown icon text-wildfire-orange" />}
          onChange={(_, { value }) => {
            if (typeof value === 'string') {
              if (isValidDateTruncCategory(value)) {
                setFormFieldState({ ...formFieldState, dateTrunc: value });
              }
            }
          }}
        />
      </Form.Group>
      <div className="categoryFiltersContainer equal width fields flex flex-row justify-between mb-2">
        <div className="categoryFilters flex flex-wrap justify-center w-full bg-[#fafafc] p-2.5 border border-[rgba(34, 36, 38, 0.15)] mx-2 text-center rounded">
          <h3 className="filterLabels m-0 font-montserrat font-semibold text-[14px]">Application</h3>
          <Form.Group className="flex !flex-col !w-full">
            <Form.Field>
              <DropdownComponent
                clearable
                fluid
                multiple
                selection
                search
                name="appsToInclude"
                className="!text-sm mt-2.5"
                placeholder="Applications to include"
                value={appsToInclude}
                searchQuery={appSearchQuery.appsToInclude}
                disabled={Boolean(appsToExclude.length)}
                loading={Boolean(!appOptions.length)}
                icon={<i className="dropdown icon text-wildfire-orange" />}
                renderLabel={label => ({
                  content: label.text,
                  className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
                })}
                onSearchChange={(_, { searchQuery }) =>
                  setAppSearchQuery({ appsToInclude: searchQuery, appsToExclude: '' })
                }
                onChange={(e, data) => handleAppFilterChange(e, data, 'appsToInclude')}
                options={filteredAppOptions.appsToInclude.map(option => ({
                  key: option.value,
                  text: option.text,
                  value: option.value,
                  content: getAppOptionContent(option),
                  style: option.groupApplications ? { fontWeight: 'bold' } : {},
                }))}
              />
            </Form.Field>
            <Form.Field>
              <DropdownComponent
                clearable
                fluid
                multiple
                selection
                search
                name="appsToExclude"
                className="!text-sm mt-2.5"
                placeholder="Applications to exclude"
                value={appsToExclude}
                searchQuery={appSearchQuery.appsToExclude}
                disabled={Boolean(appsToInclude.length)}
                loading={Boolean(!appOptions.length)}
                icon={<i className="dropdown icon text-wildfire-orange" />}
                renderLabel={label => ({
                  content: label.text,
                  className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
                })}
                onSearchChange={(_, { searchQuery }) =>
                  setAppSearchQuery({ appsToExclude: searchQuery, appsToInclude: '' })
                }
                onChange={(e, data) => handleAppFilterChange(e, data, 'appsToExclude')}
                options={filteredAppOptions.appsToExclude.map(option => ({
                  key: option.value,
                  text: option.text,
                  value: option.value,
                  content: getAppOptionContent(option),
                  style: option.groupApplications ? { fontWeight: 'bold' } : {},
                }))}
              />
            </Form.Field>
          </Form.Group>
        </div>
        <div className="categoryFilters flex flex-wrap justify-center w-full bg-[#fafafc] p-2.5 border border-[rgba(34, 36, 38, 0.15)] mx-2 text-center rounded">
          <h3 className="filterLabels m-0 font-montserrat font-semibold text-[14px]">Merchant</h3>
          <Form.Group className="flex !flex-col !w-full">
            <Dropdown
              name="merchantsToInclude"
              className="text-sm !mt-2.5"
              placeholder="Merchants to include"
              value={merchantsToInclude}
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToExclude.length)}
              icon={<i className="dropdown icon text-wildfire-orange" />}
              renderLabel={label => ({
                content: label.text,
                className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
              })}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
            <Dropdown
              name="merchantsToExclude"
              className="text-sm !mt-2.5"
              placeholder="Merchants to exclude"
              value={merchantsToExclude}
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToInclude.length)}
              icon={<i className="dropdown icon text-wildfire-orange" />}
              renderLabel={label => ({
                content: label.text,
                className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
              })}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
          </Form.Group>
        </div>
        <div className="categoryFilters flex flex-wrap justify-center w-full bg-[#fafafc] p-2.5 border border-[rgba(34, 36, 38, 0.15)] mx-2 text-center rounded">
          <h3 className="filterLabels m-0 font-montserrat font-semibold text-[14px]">Network</h3>
          <Form.Group className="flex !flex-col !w-full">
            <Dropdown
              name="networksToInclude"
              className="text-sm !mt-2.5"
              placeholder="Networks to include"
              value={networksToInclude}
              options={networkOptions}
              icon={<i className="dropdown icon text-wildfire-orange" />}
              renderLabel={label => ({
                content: label.text,
                className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
              })}
              disabled={Boolean(networksToExclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
            <Dropdown
              name="networksToExclude"
              className="text-sm !mt-2.5"
              placeholder="Networks to exclude"
              value={networksToExclude}
              options={networkOptions}
              icon={<i className="dropdown icon text-wildfire-orange" />}
              renderLabel={label => ({
                content: label.text,
                className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
              })}
              disabled={Boolean(networksToInclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="text-center">
        <Button className="orange-rounded-button" type="submit" id="submitBtn">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default SalesDashForm;
