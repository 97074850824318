import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import SalesDashForm from 'components/SalesDashboardV2/SalesDashFormV2';
import StatsChart from 'components/SalesDashboardV2/StatsChartV2';
import StatsTable from 'components/SalesDashboardV2/StatsTableV2';
import {
  SalesDashAppGroupFilterOption,
  SalesDashFilterOption,
  SalesDashStatsData,
  SalesDashFormState,
  SalesDashChartMetric,
  Merchant,
} from './../../components/SalesDashboardV2/types';
import { groupByLabel } from './Constants';
import { formatDateTime, formatFilterNumbers, formatURI, getSearchParams, isValidFilterCategory } from './helpers';

interface SalesDashLastUpdated {
  LastUpdated: string;
  statusCode: number;
}

interface SalesDashProps {
  getDashboardTableStats: (searchURI: string) => Promise<SalesDashStatsData[]>;
  getDashboardGraphStats: (searchURI: string) => Promise<SalesDashStatsData[]>;
  getDashboardLastUpdated: () => Promise<SalesDashLastUpdated>;
  getAppGroupOptions: () => Promise<SalesDashAppGroupFilterOption[]>;
  getFilterOptions: (category: string) => Promise<SalesDashFilterOption[]>;
  getMerchant: (merchantId: number) => Promise<Merchant>;
}

const today = new Date();
const oneWeekAgo = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
const tenDaysAgo = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000);

const dateToday = new Intl.DateTimeFormat('fr-CA').format(today);
const dateOneWeekAgo = new Intl.DateTimeFormat('fr-CA').format(oneWeekAgo);
const dateTenDaysAgo = new Intl.DateTimeFormat('fr-CA').format(tenDaysAgo);

export const defaultFormState: SalesDashFormState = {
  startDate: '',
  endDate: '',
  groupBy: 'application_id',
  dateTrunc: 'day',
  appsToInclude: [],
  merchantsToInclude: [],
  networksToInclude: [],
  appsToExclude: [],
  merchantsToExclude: [],
  networksToExclude: [],
};

const SalesDashboardV2 = ({
  getDashboardTableStats,
  getDashboardGraphStats,
  getDashboardLastUpdated,
  getAppGroupOptions,
  getFilterOptions,
  getMerchant,
}: SalesDashProps) => {
  const history = useHistory();

  const [formState, setFormState] = useState<SalesDashFormState>(defaultFormState);
  const [error, setError] = useState('');
  const [lastUpdatedTime, setLastUpdatedTime] = useState<Date | null>(null);
  const [isSearchParamsUpdating, setIsSearchParamsUpdating] = useState(true);

  const [chartMetric, setChartMetric] = useState<SalesDashChartMetric>({
    key: 'GrossCommissionAmount',
    text: 'Gross Commissions',
    value: 'GrossCommissionAmount',
    unit: '$',
  });

  const getLastUpdatedTime = async () => {
    try {
      const res = await getDashboardLastUpdated();
      setLastUpdatedTime(new Date(res.LastUpdated));
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
  };

  const getFormStateFromParams = async () => {
    const {
      start_date,
      end_date,
      group_by,
      date_trunc,
      application_id,
      merchant_id,
      network_id,
      not_application_id,
      not_merchant_id,
      not_network_id,
    } = getSearchParams();

    if (!start_date) return;

    const newStartDate = start_date === '10daysago' ? dateTenDaysAgo : start_date;
    const newFormState: SalesDashFormState = {
      startDate: newStartDate,
      endDate: end_date,
      groupBy: group_by,
      dateTrunc: date_trunc,
      appsToInclude: formatFilterNumbers(application_id) || '',
      merchantsToInclude: formatFilterNumbers(merchant_id) || '',
      networksToInclude: formatFilterNumbers(network_id) || '',
      appsToExclude: formatFilterNumbers(not_application_id) || '',
      merchantsToExclude: formatFilterNumbers(not_merchant_id) || '',
      networksToExclude: formatFilterNumbers(not_network_id) || '',
    };

    if (!isEqual(formState, newFormState)) {
      setFormState(newFormState);
      setIsSearchParamsUpdating(false);
    }
  };

  useEffect(() => {
    const { start_date, end_date, group_by, date_trunc, ...remainingParams } = getSearchParams();

    let searchURI = `?start_date=${start_date || dateOneWeekAgo}`; //Start date defaults to 7 days ago including today
    searchURI += `&end_date=${end_date || dateToday}`; //End date defaults to today
    searchURI += `&group_by=${group_by || 'application_id'}`;
    searchURI += `&date_trunc=${date_trunc || 'day'}`;

    for (const key in remainingParams) {
      if (isValidFilterCategory(key)) {
        if (remainingParams[key].length) {
          if (Array.isArray(remainingParams[key])) {
            remainingParams[key].forEach((value: string) => (searchURI += `&${key}=${value}`));
          } else {
            searchURI += `&${key}=${remainingParams[key]}`;
          }
        }
      }
    }

    history.push({
      pathname: '/sales-dashboard',
      search: searchURI,
    });
    getFormStateFromParams();
  }, []);

  useEffect(() => {
    if (!isSearchParamsUpdating) {
      history.push({
        pathname: '/sales-dashboard',
        search: formatURI(formState),
      });
    }
  }, [formState]);

  useEffect(() => {
    getLastUpdatedTime();
  }, []);

  return (
    <div className="salesDashboard bg-[#f3f4f9] text-center w-full font-montserrat">
      <h1 className="font-title mb-2.5">Sales Dashboard</h1>
      {lastUpdatedTime ? (
        <p className="lastUpdated text-sm font-bold text-[#b5b5b5] mb-8">
          Last updated on {formatDateTime(lastUpdatedTime)}
        </p>
      ) : (
        <></>
      )}
      {error ? (
        <>
          <div>An error occurred. Please try again in a few minutes.</div>
          <div>{error}</div>
        </>
      ) : isSearchParamsUpdating ? (
        <></>
      ) : (
        <>
          <div className="filtersContainer bg-white p-5 rounded-md shadow-lg">
            <SalesDashForm
              formState={formState}
              setFormState={setFormState}
              getAppGroupOptions={getAppGroupOptions}
              getFilterOptions={getFilterOptions}
              getMerchant={getMerchant}
              setError={setError}
            />
          </div>
          <div className="statsContainer bg-white mt-[30px] p-5 rounded-md shadow-lg mx-auto mb-10">
            <div className="chartSection mt-5">
              <StatsChart
                groupByLabel={groupByLabel[formState.groupBy]}
                formState={formState}
                searchURI={formatURI(formState)}
                chartMetric={chartMetric}
                setError={setError}
                getDashboardGraphStats={getDashboardGraphStats}
              />
            </div>
            <div className="tableSection mt-8 min-h-[200px] max-w-[1350px] mx-auto">
              <StatsTable
                groupByLabel={groupByLabel[formState.groupBy]}
                formState={formState}
                searchURI={formatURI(formState)}
                chartMetric={chartMetric}
                setChartMetric={setChartMetric}
                setError={setError}
                getDashboardTableStats={getDashboardTableStats}
              ></StatsTable>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SalesDashboardV2;
